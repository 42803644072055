import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { FaPhoneAlt } from "react-icons/fa"
import { isBrowser } from "../../../context/ApolloContext"
import theme from "../../../utils/theme"

const MatelasCallPageSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  margin: 15px 0px 0px 0px;
`

const CallPageButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: 260px;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`

const CallPageButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  background: ${({ exclusif }) =>
    exclusif ? theme.colors.button.black : theme.gradient.buttonGradient1};
  border-radius: 25px 0px;
  color: ${({ exclusif }) =>
    exclusif ? theme.colors.white.base : theme.colors.button.black};
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0px;
  cursor: pointer;
  font-family: "Museo";
  font-weight: bold;
  min-width: 160px;
  min-height: 42px;
  flex: 1;

  :hover {
    color: #fff;
    background: ${({ exclusif }) =>
      exclusif ? theme.colors.primary.exclusif : theme.colors.button.black};
    :after {
      background: ${({ exclusif }) =>
        exclusif ? theme.colors.button.black : theme.gradient.buttonGradient1};
    }
  }

  :after {
    // content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    background: ${({ exclusif }) =>
      exclusif ? theme.colors.primary.exclusif : theme.colors.button.black};
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const CallIconWrapper = styled.div`
  display: flex;
  background: ${props => props.theme.colors.button.black};
  border-radius: 50%;
  padding: 5px;
  margin: 0px 10px 0px 0px;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`

const MatelasCallPageSection = ({ intl, exclusif }) => {
  const callPageScript = () => {
    var loadBabel = function(url, callback) {
      var script = document.createElement("script")
      script.async = true
      if (script.readyState) {
        script.onreadystatechange = function() {
          if (
            script.readyState == "loaded" ||
            script.readyState == "complete"
          ) {
            script.onreadystatechange = null
            callback(window, document)
          }
        }
      } else {
        script.onload = function() {
          callback(window, document)
        }
      }
      script.src = url
      document.head.appendChild(script)
    }
    var getCookie = function(cname) {
      var objToday = new Date()
      var version = objToday
        .toISOString()
        .split("T")[0]
        .split("-")
        .join("")
      var name = cname + "="
      var decodedCookie = decodeURIComponent(document.cookie)
      var cookieArray = decodedCookie.split(";")
      for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i]
        cookie = cookie.trim()
        if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length)
        }
      }
      return version
    }
    var loadWidget = function(window, document) {
      var __cp = {
        id: "2VJTQD0Q73rxtosjrZSPm6ZJaUHa62aRZ5EU5KxPJns",
        version: "1.1",
      }
      var cp = document.createElement("script")
      cp.type = "text/javascript"
      cp.async = true
      cp.src =
        "++cdn-widget.callpage.io+build+js+callpage.js"
          .replace(/[+]/g, "/")
          .replace(/[=]/g, ".") +
        "?v=" +
        getCookie("callpage-widget-version")
      var s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(cp, s)
      if (window.callpage) {
        alert("You could have only 1 CallPage code on your website!")
      } else {
        window.callpage = function(method) {
          if (method == "__getQueue") {
            return this.methods
          } else if (method) {
            if (typeof window.callpage.execute === "function") {
              return window.callpage.execute.apply(this, arguments)
            } else {
              ;(this.methods = this.methods || []).push({
                arguments: arguments,
              })
            }
          }
        }
        window.callpage.__cp = __cp
        window.callpage("api.button.autoshow")
      }
    }
    loadBabel(
      "https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js",
      function() {
        return loadWidget(window, document)
      }
    )
  }

  useEffect(() => {
    isBrowser && window && !window.callpage && callPageScript()
  }, [])
  return (
    <MatelasCallPageSectionWrapper id="call-widget-button">
      <CallPageButtonWrapper>
        <CallPageButton href="#cp-widget" exclusif={exclusif}>
          <CallIconWrapper>
            <FaPhoneAlt size={16} color="#fff" />
          </CallIconWrapper>
          Être conseillé
        </CallPageButton>
      </CallPageButtonWrapper>
    </MatelasCallPageSectionWrapper>
  )
}

export default injectIntl(MatelasCallPageSection)
