import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"

const MatelasCallSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px 0px;
`

const Title = styled.h2`
  font-size: 32px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin: 0px 0px 10px;
`

const CallPageButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const CallButton = styled.a`
  width: calc(100% - 12px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #262626;
  border-radius: 25px 0px;
  cursor: pointer;
  overflow: hidden;
  margin: 0px 12px 0px 0px;

  @media (max-width: 600px) {
    width: calc(100% - 8px);
    margin: 0px 8px 0px 0px;
  }
`

const ShowroomButton = styled(Link)`
  width: calc(100% - 12px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #262626;
  border-radius: 25px 0px;
  cursor: pointer;
  overflow: hidden;
  margin: 0px 0px 0px 12px;

  @media (max-width: 600px) {
    width: calc(100% - 8px);
    margin: 0px 0px 0px 8px;
  }
`

const ButtonText = styled.div`
  width: 100%;
  font-family: "Museo";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  padding: 15px 20px;
  text-align: center;

  span {
    display: none;

    @media (max-width: 600px) {
      display: inline-block;
    }
  }

  @media (max-width: 600px) {
    padding: 10px 12px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  object-fit: cover;
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;

  img {
    margin: 0px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const ButtonImage = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const BottomSection = styled.div`
  display: none;
  object-fit: contain;
  position: relative;

  @media (max-width: 600px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 20px;
    display: flex;
  }
`

const ShowroomPageLink = styled(Link)`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  text-indent: -9999px;
`

const BottomImage = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const OverlayText = styled.div`
  background: #fff;
  padding: 5px 10px 3px;
  position: absolute;
  top: 10px;
  font-family: "Gotham";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;

  @media (max-width: 600px) {
    top: 15px;
    right: 15px;
    font-size: 16px;
  }
`

const MatelasCallSection = ({ intl, exclusif }) => {
  const callPageScript = () => {
    var loadBabel = function(url, callback) {
      var script = document.createElement("script")
      script.async = true
      if (script.readyState) {
        script.onreadystatechange = function() {
          if (
            script.readyState == "loaded" ||
            script.readyState == "complete"
          ) {
            script.onreadystatechange = null
            callback(window, document)
          }
        }
      } else {
        script.onload = function() {
          callback(window, document)
        }
      }
      script.src = url
      document.head.appendChild(script)
    }
    var getCookie = function(cname) {
      var objToday = new Date()
      var version = objToday
        .toISOString()
        .split("T")[0]
        .split("-")
        .join("")
      var name = cname + "="
      var decodedCookie = decodeURIComponent(document.cookie)
      var cookieArray = decodedCookie.split(";")
      for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i]
        cookie = cookie.trim()
        if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length)
        }
      }
      return version
    }
    var loadWidget = function(window, document) {
      var __cp = {
        id: "2VJTQD0Q73rxtosjrZSPm6ZJaUHa62aRZ5EU5KxPJns",
        version: "1.1",
      }
      var cp = document.createElement("script")
      cp.type = "text/javascript"
      cp.async = true
      cp.src =
        "++cdn-widget.callpage.io+build+js+callpage.js"
          .replace(/[+]/g, "/")
          .replace(/[=]/g, ".") +
        "?v=" +
        getCookie("callpage-widget-version")
      var s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(cp, s)
      if (window.callpage) {
        alert("You could have only 1 CallPage code on your website!")
      } else {
        window.callpage = function(method) {
          if (method == "__getQueue") {
            return this.methods
          } else if (method) {
            if (typeof window.callpage.execute === "function") {
              return window.callpage.execute.apply(this, arguments)
            } else {
              ;(this.methods = this.methods || []).push({
                arguments: arguments,
              })
            }
          }
        }
        window.callpage.__cp = __cp
        window.callpage("api.button.autoshow")
      }
    }
    loadBabel(
      "https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js",
      function() {
        return loadWidget(window, document)
      }
    )
  }

  useEffect(() => {
    isBrowser && window && !window.callpage && callPageScript()
  }, [])
  return (
    <MatelasCallSectionWrapper id="call-widget-button">
      <Title>Besoin de conseils ?</Title>
      <CallPageButtonWrapper>
        <CallButton href="#cp-widget">
          <ButtonText>Par téléphone</ButtonText>
          <ImageWrapper>
            <img src="https://static.percko.com/uploads/4ecd4760-0694-4425-adc9-965b3cf12e6e.jpg" />
          </ImageWrapper>
        </CallButton>
        <ShowroomButton to="/showroom-paris/?utm_medium=site&utm_source=page-matelas&utm_campaign=showroom-paris-10e">
          <ButtonText>
            Sur rendez-vous <span>à Paris</span>
          </ButtonText>
          <ImageWrapper>
            <img src="https://static.percko.com/uploads/51e79bb5-d4f9-4861-a7f4-394a7c66b031.jpg" />
            <OverlayText>
              Showroom Paris 10<sup>e</sup>
            </OverlayText>
          </ImageWrapper>
        </ShowroomButton>
      </CallPageButtonWrapper>
      <BottomSection>
        <ShowroomPageLink to="/showroom-paris/?utm_medium=site&utm_source=page-matelas&utm_campaign=showroom-paris-10e">
          showroom
        </ShowroomPageLink>
        <BottomImage
          className="lazyload"
          data-src="https://static.percko.com/uploads/4d302812-2d28-403e-b887-cb8e4ee3cdc4.jpg"
        />
        <OverlayText>
          Showroom Paris 10<sup>e</sup>
        </OverlayText>
      </BottomSection>
    </MatelasCallSectionWrapper>
  )
}

export default injectIntl(MatelasCallSection)
