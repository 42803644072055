import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"
import LyneImageSliderPopup from "../LyneImageSliderPopup"
import MatelasSommierSection from "../../MatelasSommierSection"

const MatelasImageSection = ({
  intl,
  data,
  VideoData,
  sommierData,
  productType,
  newTag,
  page,
}) => {
  const [videoLoading, setVideoloading] = useState(true)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [openImageIndex, setOpenImageIndex] = useState(0)

  const handleLoading = () => {
    setVideoloading(false)
  }

  const imageModalHandle = imageIndex => {
    setOpenImageIndex(imageIndex)
    setImageModalOpen(true)
  }
  const imageCounter = data => {
    let slider = []
    for (let i = 0; i < data.length; i = i + 2) {
      slider.push(
        <Row key={i.toString()}>
          {data[i] && (
            <ImageBox order={0} onClick={() => imageModalHandle(i)}>
              <Image src={data[i].img} alt={data[i].alt ? data[i].alt : ""} />
              {data[i].imageText && (
                <ImageText
                  order={0}
                  dangerouslySetInnerHTML={{
                    __html: data[i].imageText,
                  }}
                />
              )}
            </ImageBox>
          )}
          {data[i + 1] && (
            <ImageBox order={1} onClick={() => imageModalHandle(i + 1)}>
              <Image
                src={data[i + 1].img}
                alt={data[i + 1].alt ? data[i + 1].alt : ""}
              />
              {data[i + 1].imageText && (
                <ImageText
                  order={1}
                  dangerouslySetInnerHTML={{
                    __html: data[i + 1].imageText,
                  }}
                />
              )}
            </ImageBox>
          )}
        </Row>
      )
      {
        i == 0 &&
          sommierData &&
          slider.push(
            <MatelasSommierSection
              data={sommierData}
              mobile={false}
              key="sommier_section"
              productType={productType}
              page={page}
            />
          )
      }
    }
    return slider
  }
  return (
    <MatelasImageSectionWrapper>
      {data && typeof window !== `undefined` && (
        <LyneImageSliderPopup
          data={data}
          open={imageModalOpen}
          imageIndex={openImageIndex}
          closeHandle={() => setImageModalOpen(false)}
        />
      )}
      <Card key="abc">
        <IframeBox>
          <BannerImage
            src={
              VideoData
                ? VideoData.video_placeholder
                : "https://static.percko.com/uploads/53a5df80-6869-4051-b69a-13eb0bf73628.jpg"
            }
            loading={videoLoading ? "image" : "video"}
          />
          <ReactPlayer
            url={
              VideoData
                ? VideoData.video_url
                : "https://www.percko.com/videos/percko.com/eur-fr/surmatelas/page_surmatelas_-_video_1.mp4"
            }
            playing={true}
            // light={
            //   "https://static.percko.com/uploads/53a5df80-6869-4051-b69a-13eb0bf73628.jpg"
            // }
            controls={false}
            muted={true}
            loop={true}
            width="100%"
            height="100%"
            playsinline={true}
            playsInline
            onPlay={() => {
              handleLoading()
            }}
          />
        </IframeBox>
        {newTag && <NewOverlay>{newTag}</NewOverlay>}
        <ProductLabelBox>
          <ProductLabel
            dangerouslySetInnerHTML={{
              __html: "Fait en<br/>France",
            }}
          />
          <FlagBox />
        </ProductLabelBox>
      </Card>
      {data && (
        <ImageSection>{imageCounter(data).map(item => item)}</ImageSection>
      )}
    </MatelasImageSectionWrapper>
  )
}

const MatelasImageSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: contain;
  border-radius: 5px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  object-fit: contain;
  flex-direction: row;
  padding: 0px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 600px) {
    border-radius: 0px;
  }
`

const ImageBox = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
  margin: ${({ order }) =>
    order === 0 ? "5px 5px 5px 0px" : "5px 0px 5px 5px"};
  border-radius: 5px;
  overflow: hidden;
  cursor: zoom-in;
  position: relative;
`

const ImageText = styled.div`
  background: #fff;
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 12px;
  color: #262626;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: 15px;
  left: ${({ order }) => (order === 0 ? "15px" : "auto")};
  right: ${({ order }) => (order === 0 ? "auto" : "15px")};
`

const ImageSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 5px;

  @media (max-width: 600px) {
    display: none;
  }
`

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0;
`

const BannerImage = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: cover;
  z-index: ${({ loading }) => (loading === "image" ? "2" : "1")};
`

const IframeBox = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  aspect-ratio: 1/1;

  iframe,
  video {
    margin: 0;
    border: 0px;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  > div {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`

const NewOverlay = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  padding: 5px 10px;
  background: linear-gradient(
    79.68deg,
    #ff7e80 -8.9%,
    #ffa853 22.37%,
    #ffed7d 85.54%
  );
  border-radius: 10px 0px;
  font-size: 16px;
  font-weight: bold;
  font-family: "BebasNeueBold";
  color: #262626;
  z-index: 2;
`

const ProductLabelBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  background: #d7edf6;
  color: #262626;
  z-index: 2;
`

const ProductLabel = styled.div`
  font-family: "Museo";
  font-weight: bold;
  font-size: 10px;
  line-height: 1.2;
  padding: 0px 8px;
`

const FlagBox = styled.div`
  width: 42px;
  background: #fff;
  height: 40px;
  border-left: 14px solid #002654;
  border-right: 14px solid #ea1f1f;
`

export default injectIntl(MatelasImageSection)
